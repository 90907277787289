import moment from 'moment';

function periodicityVerboseArray() {
    return {
        MOIS: 'Mois',
        TRIMESTRE: 'Trimestres',
        SEMESTRE: 'Semestres',
        ANNEE: 'Ans'
    };
}

function dateFormat(date) {
    if (Object.prototype.toString.call(date) === '[object Date]') {
        return date.toLocaleDateString('en-EN');
    } else {
        return moment(date).format('DD/MM/YYYY');
    }
}

function reverseDateFormat(date) {
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();

    if (day < 10) {
        day = '0' + day;
    }
    if (month < 10) {
        month = '0' + month;
    }
    return year + '/' + month + '/' + day;
}

function percentage(value, total, round = 2) {
    const result = ((value / total) * 100).toFixed(round);
    if (isNaN(result)) return -1;
    return `${result} %`;
}

function base64ToBlob(base64, contentType){
    const sliceSize = 512;
    const byteCharacters = atob(base64);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    if(contentType){
        return new Blob(byteArrays, {type: contentType});
    }
    return new Blob(byteArrays);
}

export {
    periodicityVerboseArray,
    dateFormat,
    reverseDateFormat,
    percentage,
    base64ToBlob
};
