<template>
  <Loading :value="100" bar-class="bg-info" v-if="!show"></Loading>
  <div id="mainFunding" class="pb-125">
    <div>
      <h5 class="text-uppercase font-weight-bold p-2">Demande de financement</h5>
      <hr class="hr-head" />
    </div>
    <form id="fundingPage" v-if="show">
      <div class="row no-gutters">
        <div class="col-md-3 col-12" id="soc-style">
          <div class="col">
            <h3 class="text-uppercase font-weight-bold p-2">{{ customer.name }}</h3>
            <div id="logo" class="image-center">
              <img src="../assets/img/buildings.png" />
            </div>
            <div class="">
              <div class="demande-fi text-center p-1">
                <span>Demande de financement {{ funding.reference }}</span>
              </div>
              <div class="row no-gutters">
                <div class="type-contrat col-8 text-center p-1">
                  <span>{{ contract_type }}</span>
                </div>
                <div class="date col-4 text-center p-1">
                  <span>{{ date_cre_fr }}</span>
                </div>
              </div>
            </div>
            <div class="d-flex mb-2 p-3">
              <div class="mr-3">
                <fa icon="map-marker-alt" size="xl"></fa>
              </div>
              <div class="d-flex flex-column">
                {{ customer.address }} {{ customer.zip }}<br />
                {{ customer.town }} {{ customer.country }}
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center p-3">
            <div class="mr-3">
              <fa icon="barcode" size="lg"></fa>
            </div>
            <div>{{ customer.siret }}</div>
          </div>
          <hr />
          <Suspense>
            <template #default>
              <file-manager :simulation-status="this.funding.accord"></file-manager>
            </template>
            <template #fallback> Loading... </template>
          </Suspense>
        </div>
        <div class="col-md-8 m-auto col-12">
          <TeslaBar
            v-if="show"
            :loadingState="loadingState"
            :modified="isModified"
            :contractPeriodicity="contractPeriodicity"
            :duration="contractDuration"
            :fundingProps="funding"
            :modifiable="editable"
            :typeContrat="contractTypes"
            :status="funding.accord"
            @recalculateFromTeslaChanges="recalculate"
            @sendFunding="saveSimulation"
            :ref="(el) => {tesla = el;}"
          />
          <div class="row mt-3">
            <div class="col-12" v-if="errorMessage">
              <div class="alert alert-dismissible fade show" :class="[errorMessage.is_warning ? 'alert-warning' : 'alert-danger']" role="alert">
                {{ errorMessage.message }}
                <button type="button" class="close" v-on:click="resetError">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div class="col-lg-4 col-12 p-3">
              <label>Type de matériel financé</label>
              <textarea
                :readonly="!editable"
                class="form-control noresize t-1-5e<"
                placeholder="Description*"
                v-model="funding.type_materiel"
                @blur="recalculate('type_materiel', $event.target.value)"
              >
              </textarea>
              <div class="mt-3">
                <label>Commentaire</label>
                <textarea
                  :readonly="!editable"
                  class="form-control noresize t-1-5e commentaire"
                  placeholder="Commentaire sur la simulation"
                  v-model="funding.commentaire"
                  @blur="recalculate('commentaire', $event.target.value)"
                >
                </textarea>
              </div>
            </div>
            <div class="col-lg-8 col-12">
              <fieldset class="mt-4">
                <legend>Financement</legend>
                <div class="row">
                  <div class="col-lg-6 col-12">
                    <select :disabled="!editable" class="form-control mt-2" @change="recalculate('opt_mode_reglement', $event.target.value)">
                      <option
                        v-for="(value, key) in paymentChoices"
                        :key="key"
                        :value="key"
                        :selected="key === funding.opt_mode_reglement"
                      >{{ value }}
                      </option>
                    </select>
                    <select :disabled="!editable" class="form-control mt-3" @change="recalculate('opt_terme', parseInt($event.target.value))">
                      <option v-for="(term, index) in contractTerms" :key="index" :value="index" :selected="parseInt(funding.opt_terme) === index">
                        {{ term }}
                      </option>
                    </select>
                    <template v-if="funding.retained_warranty_amount && funding.retained_warranty_amount !== 0">
                      <div class="input-group mt-4 jaune-moutarde-amelie">
                        <div class="input-group-prepend">
                          <span class="input-group-text">Retenue Garantie</span>
                        </div>
                        <input
                          :readonly="true"
                          type="text"
                          class="form-control text-right"
                          placeholder="0.00"
                          :value="funding.retained_warranty_amount"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text"><fa icon="euro-sign"></fa></span>
                        </div>
                      </div>
                    </template>
                    <div v-if="canMaintenance === '1'" class="input-group mt-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text">Maintenance HT</span>
                      </div>
                      <input
                        :readonly="!editable"
                        type="text"
                        class="form-control text-right"
                        placeholder="0.00"
                        @blur="recalculate('montant_presta_trim', parseFloat($event.target.value))"
                        :value="funding.montant_presta_trim"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text"><fa icon="euro-sign"></fa></span>
                      </div>
                    </div>
                    <template v-if="canLoyerMajore === '1'">
                      <template v-if="loyerMajore.length >= 1">
                        <div class="input-group mt-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text">Loyer majoré HT</span>
                          </div>
                          <select
                            :disabled="!editable"
                            class="form-control"
                            @change="recalculate('opt_percentLoyerMajore', parseInt($event.target.value))"
                          >
                            <option
                              v-for="(value, key) in loyerMajore"
                              :key="key"
                              :value="value"
                              :selected="parseInt(funding.opt_percentLoyerMajore) === value"
                            >
                              {{ value }}
                            </option>
                          </select>
                          <div class="input-group-append">
                            <span class="input-group-text"><fa icon="euro-sign"></fa></span>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="input-group mt-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text">Loyer majoré HT</span>
                          </div>
                          <input
                            :readonly="!editable"
                            type="text"
                            class="form-control text-right"
                            placeholder="0.00"
                            @blur="recalculate('montantLoyerMajore', parseFloat($event.target.value))"
                            :value="funding.montantLoyerMajore"
                          />
                          <div class="input-group-append">
                            <span class="input-group-text"><fa icon="euro-sign"></fa></span>
                          </div>
                        </div>
                      </template>
                    </template>
                  </div>
                  <div class="col-lg-6 col-12 text-right p-3">
                    <FundingPrice class="mt-1" name="Montant HT" :price="funding.montant_total_finance" />
                    <FundingPrice class="mt-3" name="Dont Solde HT" :price="totalRachats" />
                    <FundingPrice class="mt-3" name="Coefficient" icon="percent" :price="coef" precision="3" />
                    <FundingPrice class="mt-3" name="Loyer HT" :price="funding.echeance" />
                    <FundingPrice class="mt-3" :name="periodicity" logo="stopwatch" icon="calendar" precision="0" :price="funding.duree" />
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend>Actions</legend>
                <FundingActions
                    :loadingDevis="loadingDevis"
                    :loadingState="loadingState"
                    :modified="isModified"
                    :modifiable="editable"
                    :status="funding.accord"
                    @sendFunding="saveSimulation"
                    @sendDevis="getDevis">
                </FundingActions>
              </fieldset>
            </div>
          </div>
          <template v-if="repurchasesInfos?.length">
            <hr />
            <div class="row mt-3">
              <div class="col-6"></div>
              <div v-if="editable" class="col-6 text-center gris-7 mb-2">
                <p class="back-line"></p>
                <span class="back-line-text">Sélectionner la période pour tous les contrats</span>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-6"><label>Rachats</label></div>
              <div class="col-2 text-center" @click="editable && selectAllPeriods(1)">
                <fa icon="arrow-circle-down" class="blue-1 fa-2x pointeur"></fa>
              </div>
              <div class="col-2 text-center" @click="editable && selectAllPeriods(2)">
                <fa icon="arrow-circle-down" class="blue-1 fa-2x pointeur"></fa>
              </div>
              <div class="col-2 text-center" @click="editable && selectAllPeriods(0)">
                <fa icon="arrow-circle-down" class="blue-1 fa-2x pointeur"></fa>
              </div>
            </div>
            <RepurchaseCard
              v-for="(obj, i) in repurchasesInfos"
              :key="i"
              :montantRachete="funding.montant_rachete"
              :refundingData="obj"
              :ref="
                (el) => {
                  repurchase[i] = el;
                }
              "
              :editable="editable"
              @updateMontantRachete="updateMontantRachete"
            />
          </template>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
/* eslint-disable */
import TeslaBar from '../components/TeslaBar';
import RepurchaseCard from '../components/RepurchaseCard';
import Loading from '../components/Loading';
import { fr } from 'date-fns/locale';
import FundingService from '../services/funding.service';
import moment from 'moment';
import ContractService from '../services/contract.service';
import params from '../../params.json';
import FundingPrice from '../components/AppFundingPrice';
import FileManager from '@/components/FileManager';
import FundingActions from "@/components/FundingActions.vue";
import {base64ToBlob} from "@/helpers/tools";

export default {
  name: 'FundingRequest',
  components: {FundingActions, FileManager, TeslaBar, RepurchaseCard, Loading, FundingPrice },
  props: {
    customerInfos: {
      type: Object
    }
  },
  data() {
    return {
      dateLocale: fr,
      tesla: null,
      funding: null,
      show: false,
      idCustomer: this.$route.params.id,
      newFunding: this.$route.params.idFunding,
      repurchasesInfos: null,
      repurchase: [],
      loyerMajoreInfo: this.$store.getters.getLoyerMajores,
      maintenance: this.$store.getters.getPrestations,
      errorMessage: null,
      loadingState: false,
      paymentChoices: this.$store.getters.getPayementTypes,
      editable: true,
      customer: this.customerInfos ?? null,
      contractTypes: this.$store.getters.getContractTypes,
      contractPeriodicity: this.$store.getters.getPeriodicites,
      contractTerms: this.$store.getters.getTermes,
      isModified: false,
      resetData: null,
      loadingDevis: false,
    };
  },
  methods: {
    async createNewFunding() {
      const fundingSchema = { ...params.fundingRequestDefaultValues };
      fundingSchema.fk_soc = this.idCustomer ? parseInt(this.idCustomer) : 0;
      fundingSchema.fk_user_author = this.$store.getters.getUser.userId;
      fundingSchema.entity = this.$store.getters.getUser.entity;
      fundingSchema.siret = this.customer.idprof2;
      this.newFunding = this.$route.name === 'newFunding';
      this.funding = fundingSchema;
      if(this.maintenance.service === '1') {
        this.funding.montant_presta_trim = this.maintenance.amount
      }

    },
    async updateFunding() {
      this.repurchasesInfos = await this.getRepurchases(this.customer.idprof2);
      this.funding.montant = this.funding.montant_total_finance;
      this.funding.save = false;
      this.show = true;
      if (this.newFunding && this.tesla !== null) {
        this.tesla.raz();
      }
    },
    async getFunding(id) {
      let funding = null;
      await FundingService.getFunding(id)
        .then((response) => {
          funding = response.data;
        })
        .catch((error) => {
          this.$notify({
            duration: 3000,
            text: 'Impossible de récupérer la simulation. ' + error.response.data.error.message,
            type: 'error'
          });
        });
      this.funding = funding;
      this.editable =
        !(this.funding.accord === 'WAIT' || this.funding.accord === 'WAIT_MODIF' || this.funding.accord === 'KO') &&
          Object.keys(this.funding.conformite).length === 0;
    },
    getDevis(){
      this.loadingDevis = true;
      FundingService.getSimulationDevis(this.funding).then((response) => {
        let blob = base64ToBlob(response.data.content);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(new File([blob], 'file', {type: 'application/pdf'}));
        link.download = 'Devis_'+this.funding.fk_soc;
        console.log(link);
        link.click();
      }).catch((error) => {
        console.log(error);
        this.$notify({
          duration: 3000,
          text: 'Impossible de générer un devis. ' + error.response?.data?.error.message,
          type: 'error'
        });
      }).finally(() => {
        this.loadingDevis = false
      })
    },
    async getRepurchases(siret) {
      try {
        let repurchases = await ContractService.getContracts(siret);
        return repurchases.data.filter((obj) => obj.financementLeaser.reference !== '');
      } catch (error) {
        this.errorMessage = errors.getError(error);
        return false;
      }
    },
    selectAllPeriods(period) {
      this.repurchase.map(function (rep) {
        rep.selectPeriod(period);
      });
      this.updateMontantRachete();
    },
    updateMontantRachete() {
      this.funding.montant_rachete = this.getMontantsRachats();
      this.recalculate('montant_rachete', parseFloat(this.funding.montant_rachete));
    },
    getMontantsRachats() {
      var montant_rachete = 0;
      this.repurchase.map(function (rep) {
        montant_rachete += rep.getMontantSelect();
      });
      return parseFloat(montant_rachete.toFixed(2));
    },
    normalizeNullData(field = null) {
      if (isNaN(this.funding.montant_total_finance)) {
        this.funding.montant_total_finance = 0;
      }
      if (isNaN(this.funding.montantLoyerMajore)) {
        this.funding.montantLoyerMajore = 0;
      }
      if (isNaN(this.funding.echeance)) {
        this.funding.echeance = 0;
      }

      if (field === 'montant_total_finance') {
        this.funding.montant = this.funding.montant_total_finance;
      }
      if (field === 'fk_dossier_adjonction') {
        this.funding.opt_adjonction = this.funding.fk_dossier_adjonction > 0 ? 1 : 0;
      }
      if (this.funding.date_accord === null) {
        this.funding.date_accord = '';
      }
    },
    recalculate(field, value) {
      if (!this.editable) {
        return false;
      }
      if (field === 'fk_type_contrat') {
        this.funding.fk_type_contrat = value;
        this.funding.duree = this.contractDuration[0];
      }
      if (field === 'opt_periodicite_wait') {
        this.funding.opt_periodicite = value;
        this.funding.duree = this.contractDuration[0];
      }
      if (field === 'echeance') {
        this.funding.montant = 0;
        this.funding.montant_total_finance = 0;
      }

      if (field === 'montant_total_finance' || field === 'montant') {
        this.funding.echeance = 0;
      }
      this.funding[field] = value;
      this.normalizeNullData(field);
      this.isModified = true;
      this.funding.save = false;
      this.sendFundingToApi(false);
    },
    sendFundingToApi(save) {
      this.loadingState = true
      this.errorMessage = null;
      if (
        parseInt(this.funding.fk_user_author) === 0 ||
        (parseFloat(this.funding.montant) === 0 && parseFloat(this.funding.echeance) === 0) ||
        this.funding.opt_periodicite === '' || parseFloat(this.funding.duree) === 0 ||
        this.funding.fk_type_contrat === '' ||
        this.funding.opt_mode_reglement === ''
      ) {
        this.errorMessage = {
          message: 'Informations manquantes dans la simulation'
        };
        this.loadingState = false;
        return false;
      }
      this.financementCreateSimulationModel(save)
          .then((response) => {
            this.checkSimulationResponse(response, save);
          })
          .catch((error) => {
            this.errorMessage = {
              message: error.response.data.error.message
            };
          });
      this.loadingState = false;
    },
    resetError() {
      this.errorMessage = null;
      this.loadingState = false;
    },
    financementCreateSimulationModel(save = false) {
      this.errorMessage = null;
      return new Promise((resolve, reject) => {
        FundingService.postSimulation(this.funding, save).then(
          (response) => {
            if (!isNaN(response.data)) {
              this.$notify({
                duration: 3000,
                title: 'Succès',
                text: `La simulation ${response.data} à été enregistrée`,
                type: 'success'
              });
            }
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    async checkSimulationResponse(response) {
      if (isNaN(response.data)) {
        // To be sure to transfer simulation id to new simu not saved
        response.data.rowid = this.funding.rowid;
        response.data.id = this.funding.id;
        response.data.accord = this.funding.accord;
        this.funding = response.data;
      } else if (this.customerInfos) {
        this.$router.push({ name: 'custommerFunding', params: { idFunding: response.data } });
        await this.getFunding(response.data);
      }
      this.reinitAfterReturn();
    },
    reinitAfterReturn() {
      this.funding.save = false;

      if (this.funding.opt_calage === undefined) {
        this.funding.opt_calage = 0;
      }
      this.loadingState = false;
    },
    saveSimulation() {
      this.normalizeNullData();
      this.funding.save = true;
      this.sendFundingToApi(true);
    },
  },
  computed: {
    loyerMajore(){
      return this.loyerMajoreInfo.loyermajoresvalues
    },
    canLoyerMajore(){
      return this.loyerMajoreInfo.loyermajore
    },
    canMaintenance(){
      return this.maintenance.service
    },
    contractDuration() {
      return this.contractTypes[this.funding.fk_type_contrat].durations[this.funding.opt_periodicite]
    },
    date_cre_fr() {
      return moment(this.funding.date_cre).format('DD/MM/YYYY');
    },
    contract_type() {
      return this.contractTypes[this.funding.fk_type_contrat] ? this.contractTypes[this.funding.fk_type_contrat].label : 'Not defined';
    },
    periodicity() {
      return this.contractPeriodicity[this.funding.opt_periodicite];
    },
    coef() {
      return parseFloat(this.funding.coeff);
    },
    totalRachats() {
      return this.getMontantsRachats();
    },
    opt_calage() {
      return this.funding.opt_calage !== 0 ? parseInt(this.funding.opt_calage.slice(0, -1)) : 0;
    },
  },
  async mounted() {
    if (!this.newFunding) {
      await this.createNewFunding();
    } else {
      await this.getFunding(this.$route.params.idFunding);
    }
    await this.updateFunding();

    this.funding.fk_type_contrat = Object.keys(this.contractTypes)[0];
    this.funding.duree = this.contractDuration[0]
  }
};
</script>

<style scoped>
.w-80{
  width: 80% !important;
}
#fundingPage {
  background: #fff;
  min-height: 600px;
}

#soc-style {
  background-color: #464b5f;
  color: white !important;
  padding: 1rem;
}

#fundingPage {
  margin-bottom: 150px;
}

#soc-style div p strong {
  color: white !important;
}

#soc-style #logo {
  margin: 20px;
  text-align: center;
}

#soc-style {
  line-height: 20px;
}

#soc-style #logo img {
  text-align: center;
}

#soc-style hr {
  background-color: white;
}

#soc-style h2 {
  margin: auto;
  text-align: center;
  font-size: larger;
  font-weight: 600;
}

.badge-loc {
  padding: 10px 15px;
  font-weight: normal;
  font-size: 1.1em;
}

form label,
form fieldset legend {
  font-size: 1.2em;
  color: #464b5f;
  font-family: 'Arboria';
}

form textarea,
form input {
  color: #000;
}

form fieldset,
form textarea {
  border: 1px solid lightgrey !important;
  border-radius: 0 !important;
}

form fieldset {
  padding: 0.5rem 1.5rem;
}

form fieldset legend {
  display: inline-block;
  width: auto;
  padding: 0 10px;
}

form .input-group-prepend,
form .input-group-append {
  background: #f1f1f1;
}

form .input-group-text {
  color: #6f6f6f;
}

.fundingNew {
  width: 80%;
  margin: auto;
  margin-top: 70px;
}

form .input-group input {
  border-left: 0;
  border-right: 0;
}

strong {
  color: #000;
}

.back-line {
  border-bottom: 1px solid #777;
  position: absolute;
  width: calc(100% - 30px);
  top: 8px;
  z-index: 0;
}

.back-line-text {
  background-color: #fff;
  position: relative;
  padding: 0 10px;
}
.title {
  font-size: large;
}

.commentaire {
  height: 200px !important;
}

.form-control::placeholder {
  font-size: small;
  font-style: italic;
}

h3,
h2 {
  color: white !important;
}

#soc-style p,
#soc-style span {
  color: white !important;
}

hr {
  background-color: #707070;
  margin-top: 20px;
  margin-bottom: 20px;
}

.badge-info-purple span {
  color: white !important;
}

.type-contrat {
  background-color: #464b5f;
}

.date {
  background-color: #6e398e;
}

.demande-fi {
  background-color: #9569ab;
}

.type-contrat span,
.date span,
.demande-fi span {
  color: white;
}

select {
  border-radius: 0;
  border: 1px solid lightgrey;
}

.input-group-text {
  border-radius: 0;
  border-color: lightgrey;
  color: #464b5f !important;
}

.form-control {
  border-color: lightgrey;
}

.card {
  border-radius: 0;
  border-color: black;
}

.hr-head {
  margin-top: 0 !important;
  margin-bottom: 1em !important;
  background-color: #6e398e;
  height: 1px;
}

#mainFunding{
  width: 100%;
}

.jaune-moutarde-amelie{
  border: 1px solid #e0ac00 !important;
}

</style>
