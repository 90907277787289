import Portfolio from "../views/Portfolio.vue";
import ContractPogress from "../components/ContractProgress";
import FundingProgress from "../components/FundingProgress";
import AppHeader from "../components/AppHeader"
import AppHeaderMenuCustomer from '../components/AppHeaderMenuCustomer'
import FundingRequest from "../views/FundingRequest";
import Login from "../views/Login.vue";
import Customer from "../views/Customer";
import CustomerCard from "../components/CustomerCard";
import Avatar from "../components/AppAvatar";
import Stepper from "@/components/Stepper";
import Dashboard from "@/views/Dashboard.vue";
import Maintenance from "@/views/Maintenance.vue";
import Parc from "@/views/Parc.vue";
import Tier from "@/views/Tier.vue";

export const routes = [
    {
        path: "/login",
        name: "login",
        component: Login,
    },
    {
        path: "/service/maintenance",
        name: "maintenance",
        component: Maintenance,
    },
    {
        path: "/portfolio",
        name: "portfolio",
        components: {
            default: Portfolio,
            header: AppHeader,
        },
        props: true,
    },
    {
        path: "/",
        name: "dashboard",
        components: {
            default: Dashboard,
            header: AppHeader,
        },
    },
    {
        path: "/parc",
        name: "parc",
        components: {
            default: Parc,
            header: AppHeader,
        },
    },
    {
        path: "/tiers",
        name: "tier",
        components: {
            default: Tier,
            header: AppHeader,
        },
    },
    {
        path: "/customer/:id",
        name: 'customer',
        components: {
            default: Customer,
            header: AppHeader,
        },
        props: true,
        children: [
            {
                path: 'funding/',
                name: 'funding',
                props: true,
                components: {
                    default: FundingProgress,
                    customerCard: CustomerCard,
                    headerMenu: AppHeaderMenuCustomer,
                    avatar: Avatar
                },
                meta: {
                    name: 'Demandes de financement',
                },
            },
            {
                path: 'stepper/:idFunding',
                name: 'stepper',
                props: true,
                components: {
                    default: Stepper,
                    headerMenu: AppHeaderMenuCustomer,
                    avatar: Avatar
                },
                meta: {
                    name: 'Avancement dossier',
                },
            },
            {
                path: 'contract/:siret',
                name: 'contract',
                components: {
                    default: ContractPogress,
                    customerCard: CustomerCard,
                    headerMenu: AppHeaderMenuCustomer,
                    avatar: Avatar
                },
                props: true,
                meta: {
                    name: 'Contrats de financement',
                },
            },
            {
                path: 'fundingrequest/new',
                name: 'newFunding',
                components: {
                    default: FundingRequest,
                    headerMenu: AppHeaderMenuCustomer,
                },
                props: true,
                meta: {
                    name: 'Simulateur de financement',
                },
            },
            {
                path: 'fundingrequest/:idFunding',
                name: 'custommerFunding',
                components: {
                    default: FundingRequest,
                    headerMenu: AppHeaderMenuCustomer,
                },
                props: true,
                meta: {
                    name: 'Simulation en cours',
                },
            }]
    },
];