<template>
  <router-view :key="$route.fullPath" name="header"/>
  <transition name="fadeHeader" mode="out-in" tag="div">
    <HeaderMenuCustomer :client="client" v-if="show"/>
  </transition>
  <transition name="fadeComponents" mode="out-in" tag="div" class="position-relative">
    <div v-if="show" class="row m-auto test2 w-80">
        <router-view class="col-xl-3 col-lg-6 col-12 mx-auto client-customer" :key="$route.fullPath" name="customerCard" :client="client"/>
        <router-view class="col-xl-8 col-12 mt-xl-0 mx-auto mt-4 info-customer w-80" :key="$route.fullPath" name="default" @retrieveContact="getContact()" :partner="partner" :customerInfos="client"/>
    </div>
  </transition>
</template>

<script>
import CustomerService from "../services/customer.service";
import PartenaireService  from "../services/partenaire.service";
import HeaderMenuCustomer from '../components/AppHeaderMenuCustomer';

export default {
  name: 'Customer',
  components: { HeaderMenuCustomer },
  props: {
    id: String
  },
  data() {
    return {
      client: null,
      partner: null,
      show: false
    }
  },
  methods: {
    async getCustomer(id){
      let client = null;
      await CustomerService.getCustomer(id)
          .then(response => {
            response.data.siret = response.data.idprof2;
            client = response.data;
          })
          .catch(error => {
            if (error.response.status === 404) {
              error.message = 'Impossible de récupérer les informations de l\'entreprise ' + id;
              this.$router.push('/')
            }
            this.$notify({
              duration: 5000,
              text: error.message,
              type: 'error'
            });
          });
      return client;
    },
    async getPartner(entity){
      let partner = null;
      await PartenaireService.getPartenaire(entity)
          .then(resp => {
            partner = resp.data.label;
          })
          .catch(error => {
            this.$notify({
              duration: 3000,
              text: error.message,
              type: 'warn'
            });
          });
      return partner
    }
  },
  computed: {
    isSimulated() {
      return this.$route.name === 'custommerFunding';
    }
  },
  async mounted() {
    this.client = await this.getCustomer(this.$route.params.id);
    this.partner = await this.getPartner(this.client.entity);
    this.show =  true
  }
}
</script>

<style scoped>

.w-80{
  width: 80%;
}

.info-customer{
  height: 75vh;
  margin: auto;
  padding: 0;
}


</style>


