<template>
  <div class="w-75 m-auto">
    <nav class="navbar navbar-expand-lg">
      <div class="navbar-collapse">
        <div class="navbar-nav mr-auto">
          <router-link :to="{ name: 'tier' }" class="nav-link t-20">
            <h3>Recherche un tier</h3>
          </router-link>
        </div>
      </div>
    </nav>
    <hr style="margin-top: 10px; border-top: 2px solid lightgray"/>
    <section>
      <el-input
          v-model="searchPrompt"
          size="large"
          placeholder="Rechercher un tiers par : Raison Sociale (Ex: Koesio AURA) | Siret (Ex: 381228386) | Siren (Ex: 38122838600102)"
          :prefix-icon="Search"
      />
    </section>
    <section class="result">
      <div class="row">
        <template v-if="searchLoader">
          <div class="text-center m-auto mt-5 loader"></div>
        </template>
        <template v-else-if="!searchResult">
          <section class="col-6 m-auto text-center p-5">
            <LottieAnimation
                :animation-data="require('@/assets/tier.json')"
                background="transparent"
                class="m-auto"
                speed="1"
                style="width: 80%; height: 80%"
                loop
                autoplay
            ></LottieAnimation>
          </section>
        </template>
        <template v-else-if="searchResult && !searchResult.length">
          <p class="col-6 m-auto text-center p-5">Aucun résultat</p>
        </template>
        <el-collapse v-else accordion class="accordion col-lg-10 col-md-12 col-12">
          <el-collapse-item @click="fetchSiteByCompany(result.registrationId)" :key="result" v-for="result in searchResult">
            <template #title>
                <p><span class="image"><img data-v-bb50a5e4="" class="img-building" src="/img/flat-building.ecf38a3b.png"></span> {{ result.name }} | <i>{{ result.registrationId }}</i></p>
              <el-tooltip>
                <template #content>
                  <span>Nombre de site ouvert</span>
                </template>
                <el-badge :value="result.nbOpenSites" class="item m-2" :type="result.nbOpenSites > 0 ? 'primary' : 'warning'">
                </el-badge>
              </el-tooltip>
            </template>
            <div class="text-center">
              <div v-if="loadingSite" class="loader"></div>
              <div v-else>
                <div v-if="!searchSite.length">
                  <p>Aucun site trouvé</p>
                </div>
                <div v-else class="site">
                  <button @click="createTier(site)" class="btn m-2 btn-site" :class="site.status === 'AC' ? 'btn-site-active' : 'btn-site-close'" :key="site.name" v-for="site in searchSite">
                    <div>
                      <fa class="headquarter fa-xl" icon="star" v-if="site.isHeadquarter"></fa>
                      <span class="name"> {{ getSiteName(site) }} -</span>
                      <span class="id"> {{ site.registrationId }} </span>
                      <span class="adresse"> {{ getSiteAdresse(site) }} </span>
                    </div>
                    <fa class="dossier fa-xl" icon="folder-open" v-if="site.leaseboardSimulation > 1" >
                      {{ site.leaseboardSimulation }}</fa>
                  </button>
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </section>
    <el-dialog
        v-model="dialogVisible"
        title="Attention"
        width="30%"
    >
      <span>Vous ne pouvez pas créer de simulation sur un tier inactif</span>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false">
            Fermer
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>

import _, {} from "lodash";
import OrganisationServices from "@/services/organisation.services";
import {Search} from "@element-plus/icons";
import fundingService from "@/services/funding.service";
import tierService from "@/services/tier.service";
import customerService from "@/services/customer.service";

export default {
  name: 'Tier',
  components: [],
  data() {
    return {
      searchPrompt: null,
      searchResult: null,
      searchSite: [],
      searchLoader: false,
      loadingSite: false,
      currentCompany: null,
      dialogVisible: false,
    };
  },
  watch: {
    searchPrompt: _.debounce(function(){
      this.globalSearch();
    }, 500),

  },
  computed: {
    Search() {
      return Search
    }
  },
  methods: {
    createTier(site){
      if(site.status !== 'AC' ){
        this.dialogVisible = true;
        return
      }
      this.loadingSite = true
      tierService.createThirdpartyBySiret(site.registrationId).then((response) => {
        this.$router.push({name:'funding', params:{id : response.data }})
      }).catch((error) => {
        if(error.response.status === 409){
          customerService.getCustomerBysiret(site.registrationId).then((response) => {
            this.$router.push({name:'funding', params:{id : response.data[0].id }})
          })
        }
      }).finally(() => {
        this.loadingSite = false;
      })
    },
    async globalSearch() {
      if (this.searchPrompt === '') {
        this.searchResult = null;
      }
      if (this.searchPrompt?.length < 3) {
        return
      }
      this.searchLoader = true;
      await this.fetchCompany()
    },
    getQueryFromSearchInput(search){
      const sirenRegExp = new RegExp("(\\d{9}|\\d{3}[ ]\\d{3}[ ]\\d{3})");
      let siren = sirenRegExp.exec(search);
      if(siren){
        return {'registrationId' : siren[0]}
      }
      return {'name': search}
    },
    getSiteName(site){
      return site.name || site.commercialName || site.company.name
    },
    getSiteAdresse(site){
      if(site.addresses.length){
        let adresse = site.addresses[0]
        return (adresse.houseNumber ?? '') + ' ' + (adresse.street2 || adresse.street) + ' ' + adresse.zipCode + ' ' + adresse.locality
      }
      return site.name || site.commercialName || site.company.name
    },
    async fetchCompany(){
      let query = this.getQueryFromSearchInput(this.searchPrompt)
      OrganisationServices.getCompanyByName(query).then((response) => {
        this.searchResult = response.data.items;
        this.searchLoader = false
      })
    },
    async fetchSiteByCompany(companyId){
      if(companyId === this.currentCompany){
        return
      }
      this.searchSite = []
      this.loadingSite = true;
      await OrganisationServices.getSiteByCompany(companyId).then((response) => {
        this.searchSite = response.data.items;
        this.currentCompany = companyId;
        this.searchSite.map(async (site) => {
          await fundingService.getSimulationsBySiret(site.registrationId).then((response) => {
            console.log(response)
            site.leaseboardSimulation = response.count();
          })
          return site;
        })
        this.loadingSite = false;
      })
    }
  },
};
</script>

<style scoped>

h3{
  text-transform: uppercase;
  font-weight: 700;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #FFF #FFF transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after,
.loader::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #FF3D00 #FF3D00;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}
.loader::before {
  width: 32px;
  height: 32px;
  border-color: #FFF #FFF transparent transparent;
  animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.accordion{
  margin: 20px auto 20px auto;
}

.site{
  text-align: justify;
}

.site .name{
  margin: 5px;
  font-weight: 900;
  font-size: 1rem;
}

.site .id{
  margin: 5px;
  font-weight: 900;
  font-size: 1rem;
}

.site .adresse{
  margin: 5px;
}

.site{
  margin: 5px;
}

.site .headquarter{
  color: gold;
}
.site .site_close{
  color: indianred;
}
.site .site_open{
  color: green;
}

.site .dossier{
}

.btn-site{
  width: 99%;
  display: flex;
  align-items: center;
  place-content: space-between;
  background-color: white;
}
.btn-site-active{
  color: skyblue;
  border: 1px solid skyblue;
}

.btn-site-active:hover{
  background-color: skyblue;
  color: white;
  border: 1px solid skyblue;
}

.btn-site-close{
  color: indianred;
  border: 1px solid indianred;
}

.btn-site-close:hover{
  background-color: indianred;
  color: white;
  border: 1px solid indianred;
}

.image{
  margin-right: 20px
}

.img-building{
  margin-left: 10px;
  width: 30px;
}

</style>
