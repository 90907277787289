<template>
  <div class="container">
    <div class="head">
      <h5 class="text-uppercase font-weight-bold p-2">Contrat de financement en cours</h5>
      <fa icon="handshake" class="fa-large fa-xl purple"></fa>
    </div>
    <hr />
    <div class="position-relative">
      <Loading :value="100" bar-class="bg-info" v-if="!show"></Loading>
    </div>
    <div v-if="show">
      <div v-if="contracts.length">
          <ContractCard v-for="contract in contracts" :key="contract.id" :contract="contract" :partner="partner" class="mb-3"></ContractCard>
      </div>
      <div v-else class="align-items-center justify-content-center mt-3">
        <LottieAnimation
            :animation-data="require('@/assets/empty.json')"
            background="transparent"
            class="m-auto"
            speed="1"
            style="width: 300px; height: 300px"
            loop
            autoplay
        ></LottieAnimation>
        <el-alert title="Aucune contrat de financement en cours" type="info" />
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import Loading from './Loading';
import ContractCard from './ContractProgressCard';
import ContractService from '../services/contract.service';
import { useRoute } from 'vue-router';

const router = useRoute();

let contracts = ref(null);
let show = ref(false);

onMounted(async () => {
  await ContractService.getContracts(router.params.siret).then((response) => {
    contracts.value = response.data
  })
  show.value = true;
})

</script>

<style scoped>
hr {
  margin-top: 0 !important;
  background-color: #6e398e;
  height: 1px;
}

.formControlRange {
  appearance: auto;
  cursor: default;
  padding: initial;
  border: initial;
}

input[type='range'] {
  position: absolute;
  left: 0;
  bottom: 30%;
}

input[type='range'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  border-radius: 50px;
}

input[type='range']:focus {
  outline: none;
  border-radius: 50px;
}

input[type='range']:focus::-webkit-slider-runnable-track {
  background: #6e398e;
  border-radius: 50px !important;
}

input[type='range']:focus::-ms-fill-lower {
  background: #6e398e;
}

input[type='range']:focus::-ms-fill-upper {
  background: #6e398e;
}

input[type='range']:focus::-moz-range-track {
  background-color: #6e398e;
}

input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: linear-gradient(to right, #6e398e, #6e398e);
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}

input[type='range']::-webkit-slider-thumb {
  z-index: 2;
  position: relative;
  box-shadow: 0px 0px 0px #000;
  border: 1px solid #a9a9a9;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin-top: -7px;
}

input[type='range']::-moz-range-thumb {
  z-index: 2;
  position: relative;
  box-shadow: 0px 0px 0px #000;
  border: 1px solid #a9a9a9;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #fff;
  cursor: pointer;
  -moz-appearance: none;
  margin-top: -7px;
}

input[type='range']::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: linear-gradient(to right, #0083b0, #00b4db);
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}

.form-control {
  color: #a9a9a9;
}

#filter {
  padding: 10px 20px;
  border-radius: 5px;
}
</style>
