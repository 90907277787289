class ReconductionStatut {
    status = [
        {
            id: 0,
            label: "Reconduit",
            status: "reconduit",
            color: '#23A638',
            description: 'Contrat reconduit sur la période courante'
        },
        {
            id: 1,
            label: "Reconductible",
            status: "reconductible",
            color: '#23A638',
            description: 'Contrat reconduit sur la prochaine période'
        },
        {
            id: 2,
            label: "Arret au terme",
            status: "arret",
            color: '#EE7202',
            description: 'Contrat arreté au terme de la période courante'
        },
    ]
}
export default new ReconductionStatut