<template>
  <div class="card" v-if="show" v-show="refunding.financementLeaser.reference !== ''">
    <div class="row">
      <div class="col-3 d-flex  flex-column">
        <span class="badge badge-info-light m-0 reference flex-1">{{ refunding.financement.reference }}</span>
        <p class="interval flex-fill d-flex align-items-center justify-content-center mb-0">
          {{ formatDate(refunding.financement.date_debut) }}
          <fa icon="long-arrow-alt-right" class="ml-1 mr-1 fa-lg"></fa>
          {{ formatDate(refunding.financement.date_fin) }}
        </p>
      </div>
      <div class="gris-9 pl-0 pt-1 col-3 d-md-block d-none">
      </div>
      <div ref="period" class="col-3 col-md-2 p-0 period" v-bind:class="{ selected: selectedPeriod === 1 }"
           @click="editable && selectPeriod(1, periods[0].payment)">
        <fa icon="check-circle" v-if="selectedPeriod === 1" class="checked-icon"></fa>
        <p class="mt-2">Du {{ formatDate(periods[0].date_start) }}</p>
        <p>au {{ formatDate(periods[0].date_end) }}</p>
        <p class="mt-2"><strong>{{
            vueNumberFormat(periods[0].payment, {thousand: ' ', precision: 2, suffix: ''})
          }}</strong>
          <fa icon="euro-sign" class=""></fa>
        </p>
      </div>
      <div ref="period" class="col-3 col-md-2 p-0 period" v-bind:class="{ selected: selectedPeriod === 2  }"
           @click="editable && selectPeriod(2, periods[1].payment)">
        <fa icon="check-circle" v-if="selectedPeriod === 2" class="checked-icon"></fa>
        <p class="mt-2">Du {{ formatDate(periods[1].date_start) }}</p>
        <p>au {{ formatDate(periods[1].date_end) }}</p>
        <p class="mt-2"><strong>{{
            vueNumberFormat(periods[1].payment, {thousand: ' ', precision: 2, suffix: ''})
          }}</strong>
          <fa icon="euro-sign" class=""></fa>
        </p>
      </div>
      <div ref="period" class="col-3 col-md-2 p-0 period pat-30 d-flex align-items-center justify-content-center" v-bind:class="{ selected: selectedPeriod === 0 }"
           @click="editable && selectPeriod(0, 0)">
        <fa icon="check-circle" v-if="selectedPeriod === 0" class="checked-icon"></fa>
        Ne pas solder
      </div>
    </div>
  </div>
</template>

<script>
import FundingService from "../services/funding.service";
import {dateFormat} from "@/helpers/tools";

export default {
  name: 'RepurchaseCard',
  props: {
    refundingData: {
      type: Object,
      default: function () {
        return {}
      }
    },
    editable: {
      type: Boolean
    }
  },
  data() {
    return {
      refunding: {
        type: Object,
        required: true
      },
      show: false,
      periods: null,
      amount: 0.0,
      montantRachete: 0,
      montantSelect: 0,
      selectedPeriod: 0,
    }
  },
  methods: {
    formatDate(date) {
      return dateFormat(date)
    },
    getMontantSelect() {
      return this.montantSelect;
    },
    addDay(day = 1) {
      return day * (60 * 60 * 24 * 1000);
    },
    async getPeriod(contract) {
      const today = new Date()
      let periods = [];

      await FundingService.getPayments(contract.id).then(resp => {
            resp.data.forEach( (payment, index) => {
              if (Date.parse(today) <= Date.parse(payment.date_end) + this.addDay() && Date.parse(today) >= Date.parse(payment.date_start)) {
                periods = [resp.data[index], resp.data[index + 1]];
              }
            })
          })

      return periods;
    },
    selectPeriod(period, selectedAmount) {
      if (selectedAmount === undefined && period > 0) {
        selectedAmount = this.periods[period - 1].payment;} else if (selectedAmount === undefined) {selectedAmount = 0;}
      this.montantRachete = parseFloat(selectedAmount);
      this.montantSelect = this.montantRachete;
      this.selectedPeriod = period
      if (!isNaN(selectedAmount)) {
        this.$emit('updateMontantRachete')
      }
    },
  },
  computed: {
    type_contrat() {
      return this.refunding.type_contrat.charAt(0).toUpperCase() + this.refunding.type_contrat.slice(1).toLowerCase()
    },
  },
  async mounted() {
    this.refunding = this.refundingData;
    this.periods = await this.getPeriod(this.refunding);
    this.montantRachete = parseFloat(this.montantRachete.toFixed(2));

    if (this.montantRachete === this.periods[0].payment) {
      this.selectedPeriod = 1;
      this.montantSelect = this.periods[0].payment;
    } else if (this.montantRachete === this.periods[1].payment) {
      this.selectedPeriod = 2;
      this.montantSelect = this.periods[1].payment;
    } else {
      this.selectedPeriod = 0;
    }
    this.show = true;
  }
}
</script>

<style scoped>
.card {
  border: 1px solid #A9A9A9;
  border-radius: 5px;
  margin-top: 15px;
  padding: 0;
  overflow: hidden;
}

.card strong {
  color: #4183C4;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
}

.badge.reference {
  width: 100%;
  text-align: center;
  margin: 5px 0 0 5px;
  font-weight: normal;
  font-size: 1.2em;
  padding: 15px 0;
  text-transform: uppercase;
}

.badge.reference.concurrence {
  background-color: #9569ab;
  color: #fff;
}

.period {
  color: #777;
  text-align: center;
  cursor: pointer;
}

.period strong {
  color: inherit;
}

.period.selected {
  background-color: #6e398e;
  color: #fff;
  position: relative;
}

.period.selected p{
  color: #fff;
}


.period.selected > svg{
  color: lightcoral;
}

.period.selected .checked-icon {
  position: absolute;
  left: -5px;
  top: -5px;
  font-size: 2.5em;
}

input[type=text] {
  z-index: 0;
}

.badge-info-light{
  border-radius: 0;
}

.interval{
  background-color: #464B5F;
  color: white;
  margin-top: 0 !important;
  padding: 5px;
}
</style>