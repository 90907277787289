<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <template v-if="!materiel.rowid">
              <h3 class="text-uppercase font-weight-bold p-2">Créer un matériel</h3>
            </template>
            <template v-else>
              <h3 class="text-uppercase font-weight-bold p-2">Editer un matériel</h3>
              <button class="btn btn-danger router-link-active modal-default-button" @click="deleteMateriel(materiel)">Supprimer</button>
            </template>
          </div>
          <div>
            <div class="modal-body no-gutters">
            <div class="form-group text-center">
              <input type="hidden" v-model="materiel.rowid">
              <div class="row">
                <template v-if="products.length >= 1">
                <select class="form-control col" v-model="selectedProduct" @change="overrideMateriel($event.target.value)">
                  <option value="" selected disabled>Séléctionner un produit existant</option>
                  <option v-bind:key="key" v-for="(product, key) in products">{{ product.label }}</option>
                </select>
                <span class="m-2">OU</span>
                </template>
                <input type="text" required v-model="materiel.label" @change="resetSelect" placeholder="Nom" class="form-control col">
              </div>
              <div class="row">
                <input type="text" required v-model="materiel.qty" placeholder="Quantité" class="form-control col-12 mt-2">
                <select type="text" v-model="materiel.state" class="form-control col-12 mt-2" required>
                  <option :selected="materiel.state === 1" value="1">Neuf</option>
                  <option :selected="materiel.state === 2" value="2">Occasion</option>
                </select>
              </div>
            </div>
              <div class="text-center">
                <template v-if="materiel.rowid">
                  <button class="btn btn-purple mr-3" v-on:click="$emit('close');">Annuler</button>
                  <button class="btn btn-purple-inverted" v-on:click="sendEdition">Editer</button>
                </template>
                <template v-else>
                  <button class="btn btn-purple mr-3" @click="$emit('close')">Annuler</button>
                  <button class="btn btn-purple-inverted" v-on:click="createMateriel">Créer</button>
                </template>
              </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import materielService from "@/services/materiel.service";

export default {
  name: 'Materiel',
  props: {
    show: Boolean,
    materielParent: Object,
    products: Array,
  },
  data() {
    return {
      selectedProduct: "",
      materiel: {
        label: null,
        state: null,
        qty: null,
        rowid: null,
      }
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    materielParent: function(oldVal, newVal) {
      if(typeof oldVal === 'undefined' || oldVal === null){
        this.resetChamp()
      }else{
        this.editMateriel(oldVal)
      }
    }
  },
  methods: {
    resetSelect(){
      this.selectedProduct = '';
    },
    overrideMateriel(product){
      this.materiel.label = product;
    },
    deleteMateriel(materiel){
      materielService.deleteMateriel(this.$route.params.idFunding, materiel).then(() => {
        this.emitter.emit("materielUpdated");
        this.$emit('close')
      }).catch(() => {
        this.$notify({
          duration: 3000,
          text: 'Impossible de supprimer le matériel',
          type: "error"
        })
      })
    },
    editMateriel(materiel){
      this.materiel.label = materiel.label;
      this.materiel.qty = materiel.qty;
      this.materiel.state = materiel.condition === 'Neuf' ? 1 : 2;
      this.materiel.rowid = materiel.rowid;
    },
    async createMateriel(){
      await materielService.createMateriel(this.$route.params.idFunding, this.materiel).then(() => {
        this.resetChamp()
        this.emitter.emit("materielUpdated");
        this.$notify({
          duration: 3000,
          text: 'Le matériel a été créé',
          type: "success"
        })
      }).catch(() => {
        this.$notify({
          duration: 3000,
          text: 'Impossible de créer le matériel',
          type: "error"
        })
      });
      this.$emit('close')
    },
    sendEdition(){
      materielService.updateMateriel(this.$route.params.idFunding, this.materiel).then(() => {
        this.$emit('close');
        this.emitter.emit("materielUpdated");
        this.$notify({
          duration: 3000,
          text: 'Le matériel a été édité',
          type: "success"
        })
      }).catch(() => {
        this.$notify({
          duration: 3000,
          text: 'Impossible d\'editer le matériel',
          type: "error"
        })
      });
    },
    resetChamp(){
      this.materiel.qty = null;
      this.materiel.state = null;
      this.materiel.rowid = null;
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: fit-content;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  max-width: 80%;
}

.modal-header h3 {
  margin-top: 0;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
