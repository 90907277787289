import authHeader from './authHeader';

class ContractService {
    getContracts(siret) {
        return authHeader.apiUrl()
            .get("/financement/contract_old", {
                params: {
                    idprof2: siret,
                    ongoing: 1
                }
            })
    }
    getAllContracts(sqlfilter) {
        if(sqlfilter){
            sqlfilter +=  ' AND ';
        }
        sqlfilter += '(df.status>0)';
        return authHeader.apiUrl()
            .get("/financement/contract", {
                params: {
                    sqlfilters: sqlfilter
                }
            })
    }
    exportAllContract() {
        return authHeader.apiUrl()
            .get("/financement/contract/getContractListFile")
    }
}

export default new ContractService();