import authHeader from './authHeader';


class SignService {
    postEdedocContrat(idSimulation) {
        return authHeader.apiUrl()
            .post("/financement/simulation/"+idSimulation+"/sendDocumentToEdedoc", {
                "documentType": "contrat",
            })
    }
    postEdedocPv(idSimulation) {
        return authHeader.apiUrl()
            .post("/financement/simulation/"+idSimulation+"/sendDocumentToEdedoc", {
                "documentType": "pv",
            })
    }
    cancelEdedocContrat(idSimulation) {
        return authHeader.apiUrl()
            .post("/financement/simulation/"+idSimulation+"/cancelSignProcess", {
                "documentType": "contrat",
            })
    }

    cancelEdedocPv(idSimulation) {
        return authHeader.apiUrl()
            .post("/financement/simulation/"+idSimulation+"/cancelSignProcess", {
                "documentType": "pv",
            })
    }
    getDocList(idSimulation) {
        return authHeader.apiUrl()
            .get("/financement/dict/complianceDocType/?id="+idSimulation)
    }

}

export default new SignService();
