<template>
  <div class="container">
    <div class="head">
      <h5 class="text-uppercase font-weight-bold p-2">Demande de financement en cours</h5>
      <fa icon="stopwatch" class="fa-large fa-xl header-color-card d-none d-xl-block"></fa>
    </div>
    <hr>
    <div class="position-relative">
      <Loading :value="100" bar-class="bg-info" v-if="!show"></Loading>
    </div>
    <div v-if="show">
      <div v-if="simulations && !simulations.length && !currentFilter" class="align-items-center text-center justify-content-center mt-3">
        <el-alert title="Aucune demande financement en cours" type="info" center show-icon>
          <template #default>
          </template>
        </el-alert>
        <br>
        <LottieAnimation
            :animation-data="require('@/assets/empty.json')"
            class="m-auto"
            speed="1"
            style="width: 300px; height: 300px"
            loop
            autoplay
        ></LottieAnimation>
        <br>
        <button class="btn btn-purple" @click="this.$router.push({name:'newFunding',params:{idCustomer :this.$route.params.id}})">
          <fa icon="euro-sign" class="mr-2" />
          Effectuer une nouvelle demande de financement pour cette entreprise
        </button>
      </div>
      <div v-else-if="simulations">
        <DataTable :loading="isSearching" :pagination="fundings.pagination" :dataTable="simulations" @filterData="filterSimulation" @changePage="(pageInfo) => handleTableChange(pageInfo.size, pageInfo.page, pageInfo.filters)" @changeLimit="(pageInfo) => handleTableChange(pageInfo.size, 1, pageInfo.filters)" />
      </div>
    </div>
  </div>
</template>

<style scoped>
hr{
  margin-top: 0 !important;
  background-color: #6e398e;
  height: 1px;
}
</style>

<script>
import FundingService from "../services/funding.service"
import Loading from "./Loading";
import DataTable from '../components/DataTable'

export default {
  name: 'FundingProgress',
  components: {
    Loading,
    DataTable
  },
  data() {
    return {
      isSearching: false,
      simulations: null,
      partenaire: '',
      show: false,
      showError: false,
      fundings: null,
      currentFilter: null,
    }
  },
  methods: {
    filterSimulation(filterData) {
      if(filterData.filters){
        this.currentFilter=true;
      }
      this.handleTableChange(filterData.limit, 1, filterData.filters)
    },
    async handleTableChange(limit, page = 1, filter = '') {
      this.isSearching = true;
      setTimeout(async () => {
        this.fundings = await this.getFundings(this.$route.params.id, limit, page - 1, filter);
        this.simulations = this.fundings.data;
        this.isSearching = false;
      }, 1000);
    },

    async getFundings(id, limit, page = 0, filter = '') {
      try {
        return (await FundingService.getSimulationsByThirparties(id, limit, page, filter)).data;
      } catch (error) {
        this.showError = true;
        return null;
      }
    },

  },
  async mounted() {
    this.fundings = await this.getFundings(this.$route.params.id, 20);
    this.simulations = this.fundings.data;
    this.show = true;
  }
}
</script>

