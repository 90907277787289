class PaiementStatus {
    status = [
        {
            id: 0,
            label: "Tous",
            status: "tous",
            sqlfilter: ''
        },
        {
            id: 1,
            label: "Impayé",
            status: "no-paiement",
            sqlfilter: '(df.incident_paiement=1)'
        },
        {
            id: 2,
            label: "Payé",
            status: "paiement",
            sqlfilter: '(df.incident_paiement=0)'
        },
    ]
}
export default new PaiementStatus