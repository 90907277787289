<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
              <span><fa icon="triangle-exclamation" class="fa-2xl"></fa><h3 class="ml-3">{{ title }}</h3></span>
          </div>
          <div class="modal-body">
            <p v-html="message"></p>
          </div>
          <div class="modal-footer">
            <button class="btn btn-purple" v-on:click="this.$emit('close')">Annuler</button>
            <button class="btn btn-purple" v-on:click="this.$emit('success');this.$emit('close')">Confirmer</button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'Materiel',
  props: {
    show: Boolean,
    message: String,
    title: String,
  },
  data() {
    return {

    };
  },
  methods: {

  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: fit-content;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  max-width: 80%;
}

.modal-header span{
  display: flex;
}

.modal-header h3 {
  margin-top: 0;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
