<template>
  <div v-if="loading">
    <div class="preloader"></div>
  </div>
  <div v-else-if="contracts.length">
    <el-table :data="contracts" lazy>
    <el-table-column prop="client.name" label="Client">
      <template #default="scope">
        <el-popover trigger="hover" placement="top" width="300">
          <template #default>
            <span>Siret - {{ scope.row.client.idprof2 }}</span>
          </template>
          <template #reference>
            <router-link :to="{ name: 'funding', params: { id: scope.row.client?.id } }">
              <span class="society">{{ scope.row.client?.name }}</span>
            </router-link>
          </template>
        </el-popover>
      </template>
    </el-table-column>
      <el-table-column prop="reference" label="Reference" width="auto">
        <template #default="scope">
          <el-popover trigger="hover" placement="top">
            <template #default>
              <span>Référence contrat interne</span>
            </template>
            <template #reference>
              <span>{{ scope.row.reference_contrat_interne }}</span>
            </template>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="date_cre" label="Date" width="250">
        <template #default="scope">
          <div class="date-container">
            <div class="date-progress">
              <el-popover trigger="hover" placement="top">
                <template #default>
                  <span>{{ scope.row.pourcentage_avancement }} %</span>
                </template>
                <template #reference>
                  <el-progress :percentage="roundToDecimale(scope.row.pourcentage_avancement,2)" :text-inside="true" :stroke-width="12" :format="formatProgress" :color="customColors"/>
                </template>
              </el-popover>
            </div>
            <div class="date-info">
          <el-popover trigger="hover" placement="top">
            <template #default>
              <span>Date de début</span>
            </template>
            <template #reference>
              <span class="small">{{ formatDateFR(scope.row.financement.date_debut) }}</span>
            </template>
          </el-popover>
          <el-popover trigger="hover" placement="top">
            <template #default>
              <span>Date de fin</span>
            </template>
            <template #reference>
              <span class="small">{{ formatDateFR(scope.row.financement.date_fin) }}</span>
            </template>
          </el-popover>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="echeance" label="Loyer" width="100">
        <template #default="scope">
          <el-popover trigger="hover" placement="top">
            <template #default>
              <span>{{ scope.row.echeance }} €</span>
            </template>
            <template #reference>
              <span class="right">{{ roundToDecimale(scope.row.echeance,2) }} €</span>
            </template>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="montant_prestation" label="Service" width="100">
        <template #default="scope">
          <el-popover trigger="hover" placement="top" width="auto">
            <template #default>
              <span>Maintenance</span>
            </template>
            <template #reference>
              <span class="right">{{ roundToDecimale(scope.row.financement.montant_prestation, 2) }} €</span>
            </template>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="incident_paiement" label="Paiement" width="100">
        <template #default="scope">
          <template v-if="!scope.row.financement.incident_paiement">
            <el-popover trigger="hover" placement="top" width="auto">
              <template #default>
                <span>Aucun incident de paiement</span>
              </template>
              <template #reference>
                <fa class="fa-lg right" style="color: green" icon='check'></fa>
              </template>
            </el-popover>
          </template>
          <template v-else>
            <el-popover trigger="hover" placement="top">
              <template #default>
                <span>Incident de paiement</span>
              </template>
              <template #reference>
                <fa class="fa-lg right" style="color: indianred" icon='times'></fa>
              </template>
            </el-popover>
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="reconduction" label="Reconduction" width="150">
        <template #default="scope">
          <el-popover trigger="hover" placement="top">
            <template #default>
              <span>{{ scope.row.reconductible.description }}</span>
            </template>
            <template #reference>
              <button class="btn btn-disabled-reconduit" :style="{ 'background-color': scope.row.reconductible.color}">{{ scope.row.reconductible.label }}</button>
            </template>
          </el-popover>
        </template>
      </el-table-column>
  </el-table>
  </div>
  <div v-else>
    <LottieAnimation
        :animation-data="require('@/assets/empty.json')"
        class="m-auto"
        speed="1"
        style="width: 300px; height: 300px"
        loop
        autoplay
    ></LottieAnimation>
    <el-alert title="Aucun contrat de financement présent sur l'ensemble de vos clients" type="info" />
  </div>
</template>

<script>

export default {
  name: 'DataTableContract',
  components: {},
  props: {
    contracts: { required: true },
    loading: {
      required: false,
      default: false
    }
  },
  data() {
    return {
      customColors : [
        { color: '#EA4B58', percentage: 100 },
        { color: '#EE7202', percentage: 80 },
        { color: '#23A638', percentage: 60 },
      ]
    };
  },
  mounted() {
  },
  methods: {
    formatDateFR(date){
      if(date){
        return new Intl.DateTimeFormat('FR').format(new Date(date))
      }
      return ''
    },
    roundToDecimale(value, decimal){
      return (Math.round(100 * value).toFixed(decimal)) / 100
    },
    formatProgress(){
      return ''
    },
  },
};
</script>
<style>

.el-popover.el-popper{
  min-width: 30px !important;
}

.right{
  float: right;
}

.small{
  font-size: 8px;
}

.btn-disabled-reconduit{
  border-radius: 40px;
  color: white !important;
  width: 125px;
}

.date-container{
  display: flex;
  flex-wrap: wrap;
}

.date-progress{
  width: 100%;
}

.date-info{
  display: flex;
  place-content: space-between;
  width: 100%;
}

.date-info p{
  font-size: 13px;
}

.containers{
  max-width: 400px;
  margin: 10rem auto;
}

.preloader {
  margin: 20% auto 0;
  transform: translateZ(0);
}

.preloader:before,
.preloader:after {
  content: '';
  position: absolute;
  top: 0;
}

.preloader:before,
.preloader:after,
.preloader {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  animation: animation 1.2s infinite ease-in-out;
}

.preloader {
  animation-delay: -0.16s;
}

.preloader:before {
  left: -3.5em;
  animation-delay: -0.32s;
}

.preloader:after {
  left: 3.5em;
}

@keyframes animation {
  0%,
  80%,
  100% {
    box-shadow: 0 2em 0 -1em #464b5f;
  }
  40% {
    box-shadow: 0 2em 0 0 #6e398e;
  }
}
.containers {
  position: absolute;
  left: 0;
  right: 0;
}
.containers:before {
  content: "";
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}

.paginate .el-icon {
  color: #6e398e !important;
}

.el-pagination.is-background .el-pager li:not(.is-disabled):hover {
  color: #6e398e !important;
}
</style>
