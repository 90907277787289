<template>
  <div class="position-fixed" style="z-index: 1; right: 10px; bottom: 10px">
    <el-tooltip effect="dark" placement="left">
      <template #content>
        <h5 class="text-white">Support d'aide</h5>
        <p style="color: white; font-size: 12px; margin-bottom: 5px" v-for="partner in this.partners" :key="partner">
          {{ partner.firstname + ' ' + partner.lastname + ' : ' + partner.email + ' ' + partner.user_mobile}}
        </p>
      </template>
      <fa icon="circle-info" style="color: #6e398e" class="fa-3x mt-2 fa-regular" />
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: "BoxSupport",
  data() {
    return {
      partners: this.$store.getters.getPartner,
    }
  }
}
</script>

<style scoped>

</style>