<template>
  <div class="vh-100 d-flex align-items-center">
    <section class="background-koesio d-xl-flex align-items-center d-none">
      <p class="kam-logo">KAM</p>
    </section>
    <div class="row no-gutters ml-xl-3 col-12">
      <div class="col-7 d-xl-block d-none">
        <LottieAnimation animation-link="https://lottie.host/8ca94ca3-4609-426a-844a-45439889030a/cdc7Q4V2Lo.json" speed="1" style="width: 40%; height: 500px;"  loop  autoplay>
        </LottieAnimation>
      </div>
      <div class="col-xl-5 col-12 login-form">
        <div class="text-center mb-5 center"><img src="@/assets/img/k.jpg" /></div>
        <div class="card card-container center" style="margin: 80px">
          <h3>Fink est actuellement en maintenance</h3>
          <p>Votre application revient très bientôt !</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Maintenance",
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
  mounted () {
    document.body.classList.add('bg-grey')
  },
  unmounted () {
    document.body.classList.remove('bg-grey')
  }
};
</script>

<style scoped>
  .login-image {
    max-width: 100%;
  }
  .flex{
    display: flex;
    flex-wrap: nowrap;
  }

  .btn-round span{
    color: white;
  }

  .kam-logo{
    color: white;
    width: 200px;
    font-size: 180px;
    position:relative;
    font-weight: 600;
    transform: rotate(-90deg) translate(-50%);
    -webkit-transform: rotate(-90deg) translate(-50%);
    -moz-transform: rotate(-90deg) translate(-50%);
    -ms-transform:rotate(-90deg) translate(-50%);
    -o-transform:rotate(-90deg) translate(-50%);
    line-height: 0;
  }

  .background-koesio{
    background-color: #6e398e;
    width: 100vw;
    position: absolute;
    left: calc(-50vw + 50%);
    height: 500px;
  }

  .login-form{
    background: rgb(242, 242, 242);
    padding-left: 50px;
    box-shadow: inset 20px 0px 0px indianred;
  }

  .mg2{
    margin-top: 200px;
  }

  .center{
    align-self: center;
  }

  .btn-round {
    display: block;
    justify-content: center;
    align-items: center;
    width: max-content;
    padding: 0 20px;
    height: 50px;
    background-color: #6e398e;
    border-radius: 2px;
    border-radius: 2px;
    font-size: 14px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;
    box-shadow: 0 10px 30px 0 #ab88bc;
    border: none;
    margin: 30px auto auto auto;
  }
  #formLogin label {
    color:#999 ;
  }

  .footer{
    color: gray;
  }
</style>


