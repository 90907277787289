<template>
  <div>
    <div class="head">
      <h5 class="text-uppercase font-weight-bold p-2">Identité de l'entreprise</h5>
    </div>
    <hr/>
    <div class="card panel-purple text-center mx-auto">
      <div class="card-header"><h5>{{ clientInfo.name }}</h5></div>
    <div class="card-body">
      <div class="img-center">
        <img src="../assets/img/buildings.png" class="ml-2 mt-3">
      </div>
      <div class="d-flex mb-2 p-3">
        <div class="mr-3">
          <fa icon="map-marker-alt" class="text-purple" size="xl"></fa>
        </div>
        <div class=" text-black-50 text-justify font-weight-semibold ">
          {{ clientInfo.address }} {{ clientInfo.zip }}<br>
          {{ clientInfo.town }} {{ clientInfo.country }}
        </div>
      </div>
      <div class="d-flex align-items-center p-3">
        <div class="mr-3">
          <fa icon="barcode" class="text-purple" size="lg"></fa>
        </div>
        <div>{{ lastNumberSiret }} <span class="text-muted font-weight-bolder"> ({{ clientInfo.siret }}) </span></div>
      </div>
      <hr class="border-purple opacity-50">
      <div class="col text-center text-dark contact">
        <h2 class="mb-3 text-uppercase font-weight-bold">Contact
          <button class="btn purple-button border-secondary ml-2"  @click="modalContact(contact)"><fa icon="plus"></fa></button>
        </h2>
        <div class="row">
          <div class="col-10 m-auto">
            <template v-if="contacts.length">
              <template v-for="contact in contacts" v-bind:key="contact.email">
                <div class="row">
                  <div class="col-12 no-gutters">
                    <button class="font-weight-semibold highlighted-text btn btn-white" @click="modalContact(contact)" style="z-index: 2">{{ contact.lastname+' '+contact.firstname }} ({{ contact.poste }})</button>
                  </div>
                </div>
              </template>
            </template>
            <template v-else>
              <p>Aucun contact sur cette fiche</p>
            </template>
          </div>
          <Teleport to="body">
            <Contact :show="showModal" :contactBasic="currentContact" @close="showModal = false; currentContact = null;"></Contact>
          </Teleport>
        </div>
        <hr>
        <div class="col text-center font-weight-semibold contact">
          <h2 class="mb-3">Information</h2>
          <div v-if="client.informations">
            <p>{{ client.informations }}</p>
          </div>
          <div v-else>
            <p>Aucune information sur cette fiche</p>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>

import Contact from "@/components/Contact";
import ContactService from "@/services/contact.service";

export default {
  name: 'CustomerCard',
  components: [Contact],
  data() {
    return {
      currentContact: null,
      showModal: false,
      contacts: [],
    }
  },
  methods: {
    modalContact(contact){
      this.currentContact = contact;
      this.showModal = true;
    },
    getContact(){
      ContactService.getContacts(this.$route.params.id).then((response) => {
        this.contacts = response.data
      }).catch(() => {
        this.contacts = [];
      });
    }
  },
  mounted(){
    this.emitter.on("contactUpdated", () => {
      this.getContact();
    });
    this.getContact();
  }
}

</script>

<script setup>
import {ref, defineProps, watch} from "vue";


const props = defineProps({
  client: {
    type: Object
  }
});
let clientInfo = ref(props.client)
let lastNumberSiret = ref(props.client.idprof2.substr(0,9));

watch(() => props.client, (newClient) => {
  clientInfo.value = newClient;
  lastNumberSiret.value = newClient.idprof2.substr(0,9);
});

</script>@click="modalContact(contact)"

<style scoped>

hr {
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin-top: 0 !important;
  background-color: #6e398e;
  height: 1px;
}

.w-80{
  width: 80%;
}
p {
  color: #353333
}

.edit{
  background-color: white;
}

.btn-edit{
  border: none;
  vertical-align: center;
}


.hr-head{
  margin: 0;
  margin-bottom: 1em;
  background-color: #6e398e;
  height: 1px;
}

.contact p{
  margin-top: 10px;
  color: black !important;
}

.contact p{
  margin-top: 10px;
  color: black !important;
}

.contact h2{
  font-size: larger;
}

hr{
  margin-left: 20px;
  margin-right: 20px;
}

.greyColor {
  color: #212529
}

.customer{
  background-color: #464b5f;
  color: white !important;
  padding: 20px;
  height: 100%;
}

.img-center{
  text-align: center;
}

.img-center img{
  padding-bottom: 20px;
}

.btn-white{
  color:white;
  border-radius: 0 !important;
  padding: 10px !important;
}

.btn-white:hover{
  color: #6e398e;
}

.btn-white:hover.highlighted-text::before{
  background-color: transparent;
  border: 2px solid #6e398e;
  border-radius: 0 !important;
  padding: 10px !important;
}

.action-contact{
  align-self: center;
}

.opacity-50 {
  opacity: 0.5;
}
.panel-purple {
  border: 1px solid #6e398e;
}

</style>