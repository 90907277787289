import authHeader from './authHeader';

class ContactService {
    createThirdpartyBySiret(siret) {
        return authHeader.apiUrl()
            .get("/infolegaleconnector/createcompanyfromsirenorsiret", {
                params: {
                    identifier: siret,
                    country: 'FR',
                }
            })
    }
}

export default new ContactService();