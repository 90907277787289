<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <template v-if="!contact.id">
              <h3 class="text-uppercase font-weight-bold p-2">Créer un contact</h3>
            </template>
            <template v-else>
              <h3 class="text-uppercase font-weight-bold p-2">Editer un contact</h3>
              <button class="btn btn-danger mr-3" @click="deleteContact(contact.id)">Supprimer</button>
            </template>
          </div>

          <div>
            <div class="modal-body no-gutters">
              <div class="row" style="place-content: center;">
            <div class="form-group no-gutters mr-4">
              <h6>Identité</h6>
              <div class="form-label-group col">
                <input type="text" id="contactNom" v-model="contact.lastname" placeholder="Nom" class="form-control m-2" required autofocus>
                <label for="contactNom">Nom</label>
              </div>
              <input type="hidden" v-model="contact.id">
              <div class="form-label-group col">
                <input type="text" id="contactNom" required autofocus v-model="contact.firstname" placeholder="Prenom" class="form-control m-2">
                <label for="contactPrenom">Prenom</label>
              </div>
              <div class="form-label-group col">
                <input type="text" id="contactFonction" required autofocus v-model="contact.fonction" placeholder="Fonction" class="form-control m-2">
                <label for="contactFonction">Fonction</label>
              </div>
            </div>
            <div class="form-group no-gutters">
              <h6>Informations</h6>
              <div class="form-label-group col">
                <input type="text" id="contactPhone" required autofocus v-model="contact.phone_pro" placeholder="Téléphone" class="form-control m-2">
                <label for="contactPhone">Téléphone</label>
              </div>
              <div class="form-label-group col">
                <input type="text" id="contactEmail" required autofocus v-model="contact.email" placeholder="Email" class="form-control m-2">
                <label for="contactEmail">Email</label>
              </div>
            </div>
              </div>
              <div class="text-center">
                <button class="btn btn-purple mr-3" v-on:click="$emit('close');">Annuler</button>
                <template v-if="contact.id">
                  <button class="btn btn-purple-inverted" v-on:click="sendEdition">Editer</button>
                </template>
                <template v-else>
                  <button class="btn btn-purple-inverted" v-on:click="createContact">Créer</button>
                </template>
              </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import contactService from "@/services/contact.service";

export default {
  name: 'Contact',
  props: {
    show: Boolean,
    contactBasic: Object
  },
  data() {
    return {
      contact: {
        lastname: null,
        firstname: null,
        phone_pro: null,
        email: null,
        fonction: null,
      }
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    contactBasic: function(oldVal, newVal) {
      if(typeof oldVal === 'undefined' || oldVal === null){
        this.resetChamp()
      }else{
        this.editContact(oldVal)
      }
    }
  },
  methods: {
    deleteContact(rowid){
      contactService.deleteContacts(rowid).then(() => {
        this.emitter.emit("contactUpdated");
        this.$emit('close')
      }).catch(() => {
        this.$notify({
          duration: 3000,
          text: 'Impossible de supprimer le contact',
          type: "error"
        })
      })
    },
    editContact(contact){
      this.contact.lastname= contact.lastname;
      this.contact.firstname= contact.firstname;
      this.contact.phone_pro= contact.phone_mobile;
      this.contact.email= contact.email;
      this.contact.id= contact.id;
      this.contact.fonction= contact.poste;
    },
    async createContact(){
      if(!this.contact.lastname || !this.contact.firstname || !this.contact.email || !this.contact.phone_pro || !this.contact.fonction){
        this.$notify({
          duration: 3000,
          text: 'Tout les champs du contact doivent être renseignés.',
          type: "error"
        })
        return;
      }
      await contactService.createContacts(this.contact, this.$route.params.id).then(() => {
        this.resetChamp()
        this.emitter.emit("contactUpdated");
        this.$notify({
          duration: 3000,
          text: 'Le contact a été créé',
          type: "success"
        })
        this.$emit('close')
      }).catch(() => {
        this.$notify({
          duration: 3000,
          text: 'Impossible de créer le contact',
          type: "error"
        })
      });
    },
    sendEdition(){
      if(!this.contact.lastname || !this.contact.firstname || !this.contact.email || !this.contact.phone_pro || !this.contact.fonction){
        this.$notify({
          duration: 3000,
          text: 'Tout les champs du contact doivent être renseignés.',
          type: "error"
        })
        return;
      }
      contactService.updateContacts(this.contact).then(() => {
        this.$emit('close');
        this.emitter.emit("contactUpdated");
        this.$notify({
          duration: 3000,
          text: 'Le contact a été éditée',
          type: "success"
        })
      }).catch(() => {
        this.$notify({
          duration: 3000,
          text: 'Impossible d\'editer le contact',
          type: "error"
        })
      });
    },
    resetChamp(){
      this.contact.lastname= null;
      this.contact.firstname= null;
      this.contact.phone_pro= null;
      this.contact.email= null;
      this.contact.id= null;
      this.contact.fonction= null;
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: fit-content;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  max-width: 80%;
}

.modal-header h3 {
  margin-top: 0;
}

.form-label-group label{
  color: gray !important;
  left: 10px;

}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>
