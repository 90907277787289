import authHeader from './authHeader';


class CustomerService {
    getCustomer(id) {
        return authHeader.apiUrl()
            .get("/thirdparties/" + id)
    }
    getCustomerBysiret(siret){
        return authHeader.apiUrl()
            .get("/thirdparties?sqlfilters=(t.siret%3A%3D%3A"+siret+")", {
            })
    }
    createCustomer(data) {
        return authHeader.apiUrl()
            .post("/thirdparties/", data)
    }

}

export default new CustomerService();
