import authHeader from './authHeader';


class UploadService {
    postComplianceFile(idCompliance, file, base64file, documentType) {
        return authHeader.apiUrl()
            .post("/financement/compliances/"+idCompliance+"/uploadDocument/", {
                "fileName": file.name,
                "documentCategory": documentType,
                "fileContent": base64file,
                "fileEncoding": 'base64',
                "overwriteIfExists": true
            })
    }
    getComplianceFile(idCompliance) {
        return authHeader.apiUrl()
            .get("/financement/documents", {
                params: {
                    elementname: 'fin_conformite',
                    id: idCompliance,
                }
            })
    }

    downloadComplianceFile(idCompliance, filename) {
        return authHeader.apiUrl()
            .get("/financement/documents/download", {
            params: {
                elementname: 'fin_conformite',
                id: idCompliance,
                filename: filename,
            }
        })
    }

    getCompliance(idSimulation) {
        return authHeader.apiUrl()
            .get("/financement/compliances", {
                params: {
                    sqlfilters: '(t.fk_simulation:=:' + idSimulation +')',
                }
            })
    }

    getComplianceStats() {
        return authHeader.apiUrl()
            .get("/financement/compliances/stats")
    }

    postCompliance(refSimulation) {
        return authHeader.apiUrl()
            .post("/financement/compliances/", {
                refSimulation: refSimulation,
            })
    }

    postN1Compliance(idCompliance, idUser) {
        return authHeader.apiUrl()
            .put("/financement/compliances/"+idCompliance+"/setStatusWaitN1/", {
                fk_user: idUser
            })
    }

    postN2Compliance(idCompliance, refSimulation) {
        return authHeader.apiUrl()
            .put("/financement/compliances/"+idCompliance+"/setStatusWaitN2/", {
                refContrat: refSimulation,
                dateDebutContrat: new Date()
            })
    }
}

export default new UploadService();
